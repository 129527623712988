<template>
	<div style="height: 100%">
		<a-layout style="height: 100%">
			<a-layout-content style="height: 100%">
				<div class="yj-conten" style="
            min-height: calc(100% - 48px);
            background-color: #fff;
            width: calc(100% - 32px);
          ">
					<a-card title="分享者奖励" style="width: 100%; min-height: 100%" :bordered="false">
                        <a-form-model layout="horizontal" style="width: 800px" ref="ruleForm" :rules="rules" :model="FormModel"
                                      :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">

                            <a-row style='margin:20px 0'>
                                <a-col :span="24">
                                    <label>激励类型：</label>
                                    <a-dropdown :trigger="['click']">
                                        <a-button class="ant-dropdown-link" @click="(e) => e.preventDefault()" :key="FormModel.PromotionType"
                                                  :disabled="disabled">
                                            {{ PromotionName }}
                                            <a-icon type="down" />
                                        </a-button>
                                        <a-menu slot="overlay">
                                            <template v-for="item in PromotionList">
                                                <a-menu-item :key="item.key">
                                                    <a @click="changePromotionType(item)">
                                                        {{item.value}}
                                                    </a>
                                                </a-menu-item>
                                            </template>
                                        </a-menu>
                                    </a-dropdown>
                                    <label>目标用户：</label>
                                    <a-dropdown :trigger="['click']">
                                        <a-button class="ant-dropdown-link" @click="(e) => e.preventDefault()" :key="FormModel.PromotionType"
                                                  :disabled="disabled">
                                            {{ TargetUseName }}
                                            <a-icon type="down" />
                                        </a-button>
                                        <a-menu slot="overlay">
                                            <template v-for="item in TargetUseList">
                                                <a-menu-item :key="item.key">
                                                    <a @click="changeTargetUseType(item)">
                                                        {{item.value}}
                                                    </a>
                                                </a-menu-item>
                                            </template>
                                        </a-menu>
                                    </a-dropdown>
                                    <a-input-number style="margin:0 10px" :min="1" :step="1" v-model="FormModel.TargetCount" :disabled="disabled" />
                                    <label>个，奖励</label>
                                    <a-dropdown :trigger="['click']">
                                        <a-button style="margin:0 10px" class="ant-dropdown-link" @click="(e) => e.preventDefault()" :key="FormModel.AwardType"
                                                  :disabled="disabled">
                                            {{ AwardTypeName }}
                                            <a-icon type="down" />
                                        </a-button>
                                        <a-menu slot="overlay">
                                            <template v-for="item in AwardTypeList">
                                                <a-menu-item :key="item.key">
                                                    <a @click="changeAwardType(item)">
                                                        {{
                              item.value
                                                        }}
                                                    </a>
                                                </a-menu-item>
                                            </template>
                                        </a-menu>
                                    </a-dropdown>
                                    <a-input-number style='margin:0 10px' :min="0.3" :step="0.1" v-model="FormModel.LuckMoney" :disabled="disabled"
                                                    :precision="2" v-if="FormModel.AwardType == 0" />
                                    <label v-if="FormModel.AwardType == 0">元，</label>
                                    <span>
                                        <label>奖励</label>
                                        <a-input-number style="margin:0 10px " :min="1" :step="1" v-model="FormModel.GetAwardCount" :disabled="disabled" />
                                        <label>次</label>
                                    </span>
                                </a-col>
                            </a-row>


                            <!--<a-radio-group v-model="value" v-on:change="VStoreshow" direction="horizontal">
                                <a-radio :value="0">固定金额</a-radio>
                                <a-radio :value="1">随机金额</a-radio>
                            </a-radio-group>-->
                            <br />

                            <a-button type="primary" @click="AddAward" v-show="AddAwardInfo">
                                添加
                            </a-button>
                            <a-row style='margin:20px 0 0 0; border:1px solid #f3bdbd;width:190px' v-for="(item,i) of AwardList" :key="i">
                                <span>奖项{{i+1}}</span>

                                <a-row style='margin:20px 0 0 0'>
                                    <a-col :span="24">
                                        <label>配置数量：</label>
                                        <a-input-number style="margin:0 10px " :min="1" :step="1" v-model="item.ProCount" :disabled="disabled" />
                                    </a-col>
                                </a-row>

                                <a-row style='margin:20px 0 0 0'>
                                    <a-col :span="24">
                                        <label>配置金额：</label>
                                        <a-input-number style="margin:0 10px " :min="0.3" :step="1" v-model="item.RewardMoney" :disabled="disabled" />
                                    </a-col>
                                </a-row>
                                <a-row style='margin:20px 0 0 0'>
                                    <a-col :span="24">
                                        <label>配置概率：</label>
                                        <a-input-number style="margin:0 10px " :disabled="disabled" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" :min="0" :max="100" v-model="item.Probability" />
                                    </a-col>
                                </a-row>

                                <a-button type="primary" @click="removeDomain(item)" style="margin-left:50px;margin-top:10px" v-show="DeleteButton">
                                    删除
                                </a-button>
                            </a-row>

                            <a-row v-if="FormModel.AwardType == 1">
                                <a-col :span="24">
                                    <a-button @click="showAwardPoolConfig()" :disabled="disabled" icon="plus">添加优惠券</a-button>
                                </a-col>
                            </a-row>
                            <a-row v-for="item in FormModel.AwardPool" :key="item.AwardPoolID" :gutter="[10, 10]" style="border-bottom: 1px solid #f9f9f9; margin-top: 25px">
                                <a-col :span="5">
                                    <img :src="Getsrc2(item)" style="object-fit: cover" width="80" />
                                </a-col>
                                <a-col :span="16"><strong>{{ item.Name }}</strong></a-col>
                                <a-col :span="3">
                                    <a-button size="small" type="danger" @click="deleteAwardPoolConfig(item.AwardPoolID)" :disabled="disabled">删除</a-button>
                                </a-col>
                            </a-row>


                            <a-row style='margin:20px 0 0 0'>
                                <a-col :span="24">
                                    <label>激励时间：</label>
                                    <a-range-picker :value="Time" @change="handleChange" :disabled="disabled" />
                                </a-col>
                            </a-row>
                            <a-form-model-item label="位置区域" labelAlign='left' :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperColline">
                                <a-switch v-model="FormModel.IsLocation" :disabled="disabled">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                                <a-button type="primary" value="点击选择" @click="map_display" v-show="FormModel.IsLocation" size="small" style="margin-left: 12px"
                                          :disabled="disabled">点击选择</a-button>
                            </a-form-model-item>
                            <a-form-model-item label="地址" labelAlign='left' v-show="FormModel.IsLocation" :label-col="formItemLayout.labelCol"
                                               :wrapper-col="formItemLayout.wrapperColline">
                                <a-input placeholder="地址" type="textarea" disabled v-model="mapModel.tempaddress" />
                            </a-form-model-item>



                        </a-form-model>
					</a-card>
					<a-card :bordered='false'>
						<a-button type="primary" :disabled="disabled" @click="SubmitModel('ruleForm')">
							保存
						</a-button>
					</a-card>
				</div>
			</a-layout-content>
		</a-layout>
		<div v-if="AwardPoolConfigVisible">
			<AwardPoolConfig :hide="AwardPoolConfighide" :IDList="FormModel.AwardPool" :SelectType="SelectType" :ID="FormModel.AwardPoolID"></AwardPoolConfig>
		</div>
		<a-modal v-model="mapModelDisplay" title="选择活动区域" centered okText="确定" cancelText="取消" width="900px" height="700px"
		@ok="confirmAddress">
			&nbsp;<a-input type="text" id="suggestId" v-model="tempaddress" placeholder="请输入地址" />
			<div id="searchResultPanel" style="border:1px solid #C0C0C0;width:150px;height:auto; display:none;"></div>
			<div style="width: 100%; height: auto; margin-top:-40px;">
				<div id="map_title">
					请以当前
					<a href="javascript:void(0)" onclick="map_location();" style="color: red; font-weight: 400; font-size: 20px">您的位置</a>为中心，选择本次活动范围。
					<br />
					<span>备注：可拖动红色中心标示，改变您当前位置</span>
				</div>
				<div id="map_sel">
					<a-select :default-value="circlePath.radius" style="width: 120px" @change="radiusChange">
						<a-select-option value="0">请选择活动区域</a-select-option>
						<a-select-option value="5000">5公里</a-select-option>
						<a-select-option value="10000">10公里</a-select-option>
						<a-select-option value="15000">15公里</a-select-option>
						<a-select-option value="20000">20公里</a-select-option>
						<a-select-option value="30000">30公里</a-select-option>
						<a-select-option value="50000">50公里</a-select-option>
						<a-select-option value="100000">100公里</a-select-option>
						<a-select-option value="-1">不限制</a-select-option>
					</a-select>
				</div>
				<baidu-map class="bm-view" ak="E8f3c743837925ce120eb93417f478db" :center="mapModel.center" :zoom="mapModel.zoom"
				:scroll-wheel-zoom="true" @ready="mapReady">
					<bm-marker :position="mapModel.center" :dragging="true" @dragend="dragend">
					</bm-marker>
					<bm-circle :center="circlePath.center" :radius="circlePath.radius" :editing="circlePath.editing" stroke-color="blue"
					:stroke-opacity="0.5" :stroke-weight="2"></bm-circle>
				</baidu-map>
			</div>
			<div id="l-map" style="display:none;"></div>
		</a-modal>
	</div>
</template>
<script src="https://api.map.baidu.com/api?v=2.0&ak=E8f3c743837925ce120eb93417f478db"></script>
<script>
	import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import AwardPoolConfig from "@/Views/MaterialModule/components/AwardPool";
    import moment from "moment";
    import BaiduMap from "vue-baidu-map/components/map/Map.vue";
    import { BmCircle } from "vue-baidu-map";
    import { BmMarker } from "vue-baidu-map";
    export default {
        name: "",
        data() {
            return {
                disabled: false,
                rules: {
                    GetAwardCount: [
                        { message: "请输入上限次数", required: true, trigger: "blur" },
                    ],
                    TargetCount: [
                        { message: "请输入目标数量", required: true, trigger: "blur" },
                    ],
                    BeginTime: [
                        { message: "请输入开始时间", required: true, trigger: "change" },
                    ],
                    EndTime: [
                        { message: "请输入结束时间", required: true, trigger: "change" },
                    ],
                },
                FormModel: {
                    PromotionType: 0,
                    AwardType: 0,
                    LuckMoney: 0.3,
                    AwardPoolID: 0,
                    AwardPool: [],
                    GetAwardCount: 1,
                    TargetCount: 1,
                    BeginTime: "",
                    EndTime: "",
                    TargetUseType: '',
                    ProCount: 0,
                    RewardMoney: 0.3,
                    Probability: 0,

                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 5 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 19 },
                    },
                },
                SelectType: "radio",
                AwardPoolConfigVisible: false,
                ID: 0,
                endOpen: false,
                PromotionName: "拉新",
                AwardTypeName: "红包",
                PromotionList: [
                    { key: 0, value: "拉新" },
                    { key: 1, value: "购买" },
                    { key: 2, value: "领券" },
                    { key: 3, value: "阅读" },
                    //{ key: 4, value: "邀请" },
                ],
                TargetUseName:"分享人",
                TargetUseList: [
                    { key: 0, value: "分享人" },
                    { key: 1, value: "阅读人" },
                    { key: 2, value: "团员" },
                ],
                AwardTypeList: [
                    { key: 0, value: "红包" },
                    { key: 1, value: "优惠卷" },
                ],
                Time: [],
                mapModelDisplay: false,
                circlePath: {
                    center: {
                        lng: 114.383176,
                        lat: 36.062233,
                    },
                    radius: "10000",
                    editing: false,
                },
                mapModel: {
                    center: { lng: 114.383176, lat: 36.062233 },
                    zoom: 13,
                    tempaddress: "以【 河南省安阳市文峰区弦歌大道 】为中心方圆【 10 】公里",
                },
                tempaddress: '',
                isloadac: 0,
                TypeShow: false,
                AwardList: [],
                SumProbability: 0,
                DeleteButton: false,
                AddAwardInfo: false,
                value: 0,

            };
        },
        props: {
            //组件属性
        },
        methods: {

            VStoreshow: function () {
                var self = this;
                if (self.value == 1 && self.disabled == 0) {
                    self.AddAwardInfo = true;

                }
                if (self.value == 1 && self.disabled == 0 && self.ID != 0) {
                    self.AddAwardInfo = false;
                }

                if (self.value == 1 && self.disabled == 0 && self.ID == 0) {
                    self.AddAwardInfo = true;
                    self.DeleteButton = true;
                }
            },


            AddAward() {
                this.AwardList.push({
                    ID: 0,
                    ProCount: 0,
                    RewardMoney: 0,
                    Probability: 0,
                });
                console.log("this.AwardList", this.AwardList);
            },

            removeDomain(item) {
                var index = this.AwardList.indexOf(item)
                if (index !== -1) {
                    this.AwardList.splice(index, 1)
                }
            },

            //方法
            showAwardPoolConfig: function () {
                var self = this;
                self.AwardPoolConfigVisible = true;
            },
            AwardPoolConfighide: function (e, info) {
                var self = this;
                if (e == 1) {
                    if (info.length > 0) {
                        self.FormModel.AwardPoolID = info[0].AwardPoolID;
                        self.FormModel.AwardPool = info;
                    } else {
                        self.FormModel.AwardPoolID = 0;
                        self.FormModel.AwardPool = [];
                    }
                }
                self.AwardPoolConfigVisible = false;
            },
            Getsrc2(e) {
                var src = "/img/nourl.b1240360.png";
                if (e.PrizeType == 2) {
                    src = e.Pic;
                }
                return src;
            },
            deleteAwardPoolConfig(e) {
                console.log(e);
                var self = this;
                self.FormModel.AwardPoolID = 0;
                self.FormModel.AwardPool = [];
            },
            disabledStartDate(startValue) {
                const endValue = this.FormModel.EndTime;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            disabledEndDate(endValue) {
                const startValue = this.FormModel.BeginTime;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleEndOpenChange(open) {
                this.endOpen = open;
            },
            loadData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetPromoteStimulateSharerConfigInfo",
                    data: {
                        id: self.ID,
                    },
                    OnSuccess: function (data) {
                        self.FormModel.CustomID = data.data.CustomID;
                        if (self.ID != 0) {                           
                            self.TargetUseName = ["分享人", "阅读人", "团员"][data.data.TargetUserType];
                            self.FormModel = data.data;
                            self.FormModel.TargetUseType = data.data.TargetUserType;
                            self.FormModel.AwardType = data.data.AwardType;
                            self.FormModel.LuckMoney = data.data.LuckMoney;
                            self.FormModel.AwardPoolID = data.data.AwardPoolID;
                            self.FormModel.GetAwardCount = data.data.GetAwardCount;
                            self.FormModel.PromotionType = data.data.PromotionType;
                            self.FormModel.TargetCount = data.data.TargetCount;
                            self.value = data.data.CollocationType;
                            //self.FormModel.Probability = data.data.Probability == 0 ? "0" : data.data.Probability;
                            //self.FormModel.ProCount = data.data.ProCount== 0 ? "0" :data.data.ProCount;
                            //self.FormModel.RewardMoney = data.data.RewardMoney== 0 ? "0" :data.data.RewardMoney;
                            self.FormModel.BeginTime = util.TimeStamptoDateTime(
                                data.data.BeginTime,
                                "yyyy-MM-dd hh:mm:ss"
                            );
                            self.FormModel.EndTime = util.TimeStamptoDateTime(
                                data.data.EndTime,
                                "yyyy-MM-dd hh:mm:ss"
                            );
                            self.Time = [
                                moment(self.FormModel.BeginTime, "YYYY-MM-DD"),
                                moment(self.FormModel.EndTime, "YYYY-MM-DD"),
                            ];
                            self.FormModel.AwardPool = data.data.AwardPool;
                            self.PromotionList.forEach((e) => {
                                if (e.key == self.FormModel.PromotionType) {
                                    self.PromotionName = e.value;
                                }
                            });
                            self.AwardTypeList.forEach((e) => {
                                if (e.key == self.FormModel.AwardType) {
                                    self.AwardTypeName = e.value;
                                }
                            });
                            if (self.FormModel.IsLocation) {
                                if (data.data.Radius == -1) {
                                    self.circlePath.radius = data.data.Radius.toString();
                                } else {
                                    self.circlePath.radius = (
                                        data.data.Radius * 1000
                                    ).toString();
                                }
                                self.circlePath.center.lat = data.data.Lat;
                                self.circlePath.center.lng = data.data.Lng;
                                self.mapModel.center.lat = data.data.Lat;
                                self.mapModel.center.lng = data.data.Lng;
                                self.mapModel.tempaddress =
                                    data.data.CenterPointAddress;
                            }
                        }
                    },
                };
                http.Post(op);
            },
            //加载分享激励奖品配置信息GetProStiShareConfigInfo
            loadReward: function () {
                var self = this;
                  var op = {
                    url: "/MarketingModule/Promote/GetProStiShareConfigInfo",
                    data: {
                        id: self.ID,
                    },
                      OnSuccess: function (data) {
                          console.log("loadRward", data);
                          if (self.ID != 0) {
                              self.AwardList = data.data;
                        }
                    },
                };
                http.Post(op);
            },

            SubmitModel(formName) {
                var self = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (self.checkAwardConfig() && self.checkinput()) {
                            var op = {
                                url: "/MarketingModule/Promote/EditPromoteStimulateSharerConfig",
                                data: {
                                    ID: self.ID,
                                    CustomID: self.FormModel.CustomID,
                                    AwardType: self.FormModel.AwardType,
                                    LuckMoney: self.FormModel.LuckMoney,
                                    AwardPoolID: self.FormModel.AwardPoolID,
                                    GetAwardCount: self.FormModel.GetAwardCount,
                                    PromotionType: self.FormModel.PromotionType,
                                    TargetCount: self.FormModel.TargetCount,
                                    BeginTime: self.FormModel.BeginTime,
                                    EndTime: self.FormModel.EndTime,
                                    IsLocation: self.FormModel.IsLocation,
                                    Lat: self.FormModel.Lat,
                                    Lng: self.FormModel.Lng,
                                    Radius: self.FormModel.Radius,
                                    CenterPointAddress: self.FormModel.CenterPointAddress,
                                    TargetUserType: self.FormModel.TargetUseType,
                                    AwardList: self.AwardList,
                                    CollocationType:self.value,
                                },
                                OnSuccess: function (response) {
                                    console.log("forname+response", formName + response);
                                    console.log(formName + response);
                                    self.$message.success("保存成功");
                                    self.$router.push({
                                        name: "PromoteStimulateSharerConfig_List",
                                    });
                                },
                            };
                            http.Post(op);
                        } else {
                            return false;
                        }
                    }
                });
            },
            checkAwardConfig: function () {
                var rt = true;
                var self = this;
                if (
                    self.FormModel.AwardType == 0 &&
                    (self.FormModel.LuckMoney == 0 || self.FormModel.LuckMoney == null)
                ) {
                    rt = false;
                    self.$message.error("请输入奖励金额！");
                }
                if (self.FormModel.AwardType == 1 && self.FormModel.AwardPoolID == 0) {
                    rt = false;
                    self.$message.error("请选择优惠券！");
                }
                return rt;
            },
            checkinput: function () {
                var rt = true;
                var self = this;
                if (self.FormModel.TargetCount == null) {
                    rt = false;
                    self.$message.error("请输入数量！");
                }
                if (self.FormModel.BeginTime == "") {
                    rt = false;
                    self.$message.error("请输入开始时间！");
                }
                if (self.FormModel.EndTime == "") {
                    rt = false;
                    self.$message.error("请输入结束时间！");
                }
                if (self.FormModel.GetAwardCount == null) {
                    rt = false;
                    self.$message.error("请输入上限次数！");
                }
                if (self.FormModel.Probability == null) {
                    rt = false;
                    self.$message.error("请输入配置概率");
                }
                if (self.FormModel.ProCount == null) {
                    rt = false;
                    self.$message.error("请输入配置数量");
                }
                if (self.FormModel.RewardMoney == null) {
                    rt = false;
                    self.$message.error("请输入配置金额");
                }

                for (var i = 0; i < self.AwardList.length; i++) {
                    var NewProbability = parseInt(self.AwardList[i]["Probability"]);
                    self.SumProbability += NewProbability;
                }
                if (self.SumProbability > 100) {
                    rt = false;
                    self.$message.error("中奖概率总和不能大于100");
                    self.SumProbability = 0;
                }
                //if (self.FormModel.RewardMoney < 0.3) {
                //    console.log("self.FormModel.RewardMoney", self.FormModel.RewardMoney);

                //    rt = false;
                //    self.$message.error("配置奖品金额不能小于0.3");
                //}


                return rt;
            },
            changePromotionType: function (e) {
                var self = this;
                console.log("提示：", e.key);
                self.FormModel.PromotionType = e.key;
                self.PromotionName = e.value;
            },
            changeTargetUseType:function(e){
                var self = this;
                console.log("提示：", e.key);
                self.FormModel.TargetUseType=e.key;
                self.TargetUseName = e.value;
            },
            changeAwardType: function (e) {
                var self = this;
                self.FormModel.AwardType = e.key;
                self.AwardTypeName = e.value;
            },
            handleChange(value) {
                var self = this;
                console.log("提示：", value);
                self.Time = value;
                if (value.length == 0) {
                    self.FormModel.BeginTime = "";
                    self.FormModel.EndTime = "";
                } else {
                    self.FormModel.BeginTime = util.formatDate(self.Time[0]._d);
                    self.FormModel.EndTime = util.formatDate(self.Time[1]._d);
                }
            },
            map_display: function () {
                var self = this;
                if (self.circlePath.radius == -1 || self.circlePath.radius == 0) {
                    self.FormModel.Radius = -1;
                } else {
                    self.FormModel.Radius = self.circlePath.radius / 1000;
                }
                self.FormModel.Lng = self.mapModel.center.lng;
                self.FormModel.Lat = self.mapModel.center.lat;
                self.FormModel.CenterPointAddress = self.mapModel.tempaddress;
                self.mapModelDisplay = true;
            },
            confirmAddress: function () {
                var self = this;
                self.FormModel.Lng = self.mapModel.center.lng;
                self.FormModel.Lat = self.mapModel.center.lat;
                if(self.tempaddress!=null && self.tempaddress!="")
                {
                   self.mapModel.tempaddress= self.tempaddress;
                }
                else{
                    self.mapModel.tempaddress=self.FormModel.CenterPointAddress;
                }
                
                
                console.log("提示：", "1");
                self.mapModelDisplay = false;
                //确认地理位置
            },
            radiusChange: function (value) {
                var self = this;
                console.log("提示：", value);
                self.circlePath.radius = value;
                if (value == -1 || value == 0) {
                    self.FormModel.Radius = -1;
                } else {
                    self.FormModel.Radius = value / 1000;
                }
            },
            mapReady: function ({ BMap, map }) {
                console.log(map);
                var self = this;
                self.gec = new BMap.Geocoder();
                self.FormModel.Lng = self.mapModel.center.lng;
                self.FormModel.Lat = self.mapModel.center.lat;
            },
            dragend: function (type) {
                var self = this;
                self.circlePath.center = type.point;
                self.FormModel.Lng = type.point.lng;
                self.FormModel.Lat = type.point.lat;

                self.mapModel.center.lng = type.point.lng;
                self.mapModel.center.lat = type.point.lat;
                self.gec.getLocation(type.point, function (rs) {
                    var addComp = rs.addressComponents;
                    var trainPlace =
                        addComp.province +
                        addComp.city +
                        addComp.district +
                        addComp.street +
                        addComp.streetNumber;
                    var content = trainPlace;
                    self.tempaddress = "";
                    self.FormModel.CenterPointAddress = content;
                    console.log("移动点的获取位置：",type);
                });
            },
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
            "tempaddress": function () {
                console.log("dfjsa:", this.tempaddress);
                console.log("监听");
                var self = this;
                if (self.isloadac)
                    return 1;
                self.isloadac = 1;
                // 创建Map实例
                var map = new BMap.Map("l-map");
                console.log(map)
                var ac = new BMap.Autocomplete({
                    "input": 'suggestId',
                    "location": map
                })
                ac.addEventListener("onhighlight", function (e) {  //鼠标放在下拉列表上的事件
                    console.log("E:", e)

                    var str = "";
                    var _value = e.fromitem.value;
                    var value = "";
                    if (e.fromitem.index > -1) {
                        value = _value.province + _value.city + _value.district + _value.street + _value.business;
                    }
                    str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;

                    value = "";
                    if (e.toitem.index > -1) {
                        _value = e.toitem.value;
                        value = _value.province + _value.city + _value.district + _value.street + _value.business;
                    }
                    str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
                    document.querySelector("#searchResultPanel").innerHTML = str;

                });

                var myValue;
                ac.addEventListener("onconfirm", function (e) {    //鼠标点击下拉列表后的事件
                    console.log('eeee', e);
                    var _value = e.item.value;
                    myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
                    document.querySelector("#searchResultPanel").innerHTML = "onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
                    setPlace();
                    self.tempaddress = myValue;
                    console.log("获取地址信息：", e.currentTarget.ej);
                    console.log("现在地址：", self.mapModel.tempaddress);
                });
                function setPlace() {
                    map.clearOverlays();    //清除地图上所有覆盖物
                    function myFun() {

                        var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
                        console.log("pp", pp);
                        self.mapModel.center.lat = pp.lat;
                        self.mapModel.center.lng = pp.lng;
                        self.circlePath.center.lat = pp.lat;
                        self.circlePath.center.lng = pp.lng;
                        console.log(self.mapModel.center);
                        var value = 10000;
                        self.radiusChange(value);
                        //map.centerAndZoom(pp, 18);
                        //map.addOverlay(new BMap.Marker(pp));
                        //添加标注
                    }
                    var local = new BMap.LocalSearch(map, { //智能搜索
                        onSearchComplete: myFun
                    });

                    local.search(myValue);

                }

            }
        },
        components: {
            //组件
            AwardPoolConfig,
            BaiduMap,
            BmCircle,
            BmMarker,
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            var self = this;
            if (self.$route.query.disabled == 1) {
                self.disabled = true;
            }
            self.ID = self.$route.query.ID;
            self.loadData();
            self.loadReward();
            if (self.ID != 0 && self.disabled == 0) {
                self.DeleteButton = false;
                self.AddAwardInfo = false;
            }
            //if (self.ID != 0 && self.disabled == 0) {
            //    self.AddAwardInfo = true;
            //    self.DeleteButton=
            //}
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    };
</script>
<style type="text/css">
	.bm-view {
		width: 100%;
		height: 600px;
	}

	#suggestId {
		width: 500px;
		top: -68px;
		margin-left: 100px;
	}

	#map_title {
		position: absolute;
		z-index: 9999;
		left: 23px;
		right: 23px;
		height: 60px;
		background-color: #09f;
		padding-top: 10px;
		padding-left: 20px;
		color: white;
	}

	.tangram-suggestion {
		z-index: 199999
	}

	#map_sel {
		position: absolute;
		z-index: 9999;
		right: 106px;
		top: 92px;
	}

	#map_btns {
		position: absolute;
		z-index: 9999;
		bottom: 30px;
		width: 100%;
	}

	#map_btns input[type='button'] {
		width: 120px;
		height: 45px;
		margin-top: 20px;
		color: #fff;
		border: none;
		border-radius: 30px;
		opacity: 0.8;
	}

	.map_save {
		background-color: green;
		margin-right: 40px;
	}

	.map_cancel {
		background-color: #f15b5b;
	}

	.solid-btn {
		background: #32a9ff;
		color: #fff !important;
		padding: 4px 18px;
		border: none;
		border-radius: 2px;
		transition: all .15s ease-in-out;
		margin-top: -3px;
	}

	.yj-storecon-template>div {
		height: 100px;
		width: 100px;
		overflow: hidden;
		text-align: center;
		flex-shrink: 0;
	}
</style>
